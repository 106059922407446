<template>
<div class="t-top-card">
  <img src="../../../../../assets/img/data/tongji.png" alt="" class="t-top-card-img">
  <div class="t-top-card-data">
    <div class="t-top-card-data-count">
      <div class="t-top-card-data-count-number">{{number || 0}}</div>
      <div class="t-top-card-data-count-text">人</div>
    </div>
    <div class="t-top-card-data-text">
      {{text}}
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "TopCard",
  props:{
    number:[Number,String],
    text:String
  }
}
</script>

<style scoped lang="scss">
.t-top-card{
  width: 280rem;
  height: 126rem;
  background: linear-gradient(26deg, #F5F8FF 0%, #FFFFFF 100%);
  border: 2rem solid #fff;
  box-shadow: 2rem 2rem 5rem rgba(133, 161, 224, 0.20);
  border-radius: 16rem;
  display: flex;
  align-items: center;
  &-img{
    width: 80rem;
    height: 80rem;
    margin-left: 13rem;
    margin-right: 10rem;
  }
  &-data{
    &-count{
      display: flex;
      align-items: flex-end;
      font-size: 14rem;
      color: #1C4EFD;
      &-number{
        font-size: 30rem;
        font-weight: 700;
        color: #1C4EFD;
        width:125rem;
      }
    }
    &-text{
      font-size: 16rem;
      color: #1E3AA2;
      margin-top: 20rem;
    }
  }
}
</style>

<template>
<div class="t-bottom-table">
  <div class="t-bottom-table-top">
    <div class="t-bottom-table-top-title">{{title}}</div>
    <div class="t-bottom-table-top-subtitle">{{subtitle}}</div>
  </div>
  <div class="t-bottom-table-content">
    <el-table :data="data" size="mini" :cell-style="{border:'none'}" :header-row-style="{background:'rgba(170, 196, 255, .2)'}" :header-cell-style="{background:'transparent',color:'#1E3AA2'}">
      <template  v-for="(item,index) in tableConfig">
        <el-table-column :label="item.label" v-if="index === 0" :width="item.width" align="center">

          <template slot-scope='scope'>
            <div style="text-align: center">
              <img :src="medal['medal'+scope.$index]" v-if="scope.$index < 3" class="t-bottom-table-content-img" alt="">
              <div v-else class="t-bottom-table-content-level">{{scope.$index+1}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="item.label" :prop="item.prop" :width="item.width" :align="item.align||'left'" v-else>

          <template slot-scope='scope'>
            <span>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </template>

    </el-table>
  </div>
</div>
</template>

<script>
import medal0 from "@/assets/img/data/medal0.png"
import medal1 from "@/assets/img/data/medal1.png"
import medal2 from "@/assets/img/data/medal2.png"
export default {
  name: "BottomTable",
  props:{
    title:String,
    subtitle:String,
    tableConfig:Array,
    data:Array
  },
  data(){
    return {
      medal:{
        medal0,
        medal1,
        medal2
      }

    }
  },
  methods:{
    log:console.log
  }
}
</script>

<style scoped lang="scss">
.t-bottom-table{
  width: 516rem;
  &-top{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 16rem;
    &-title{
      font-size: 24rem;
      color: #1E3AA2;
      line-height: 34rem;
    }
    &-subtitle{
      font-size: 16rem;
      color: #888888;
      line-height: 22rem;
      margin-right:16rem;
    }
  }
  &-content{
    background-color: white;
    border-radius: 8rem;
    padding: 12rem 24rem;
    &-img{
      width: 24rem;
      height: 24rem;
    }
    &-level{
      width: 20rem;
      height: 20rem;
      border-radius: 2rem;
      background-color: #AAC4FF;
      color: #fff;
      font-size: 14rem;
      line-height: 20rem;
      text-align: center;
      display: inline-block;
    }
  }
}
</style>

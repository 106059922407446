<template>
  <div class="t-ec-card" :style="{width:width+'rem',marginRight:marginRight+'rem'}">
    <div class="t-ec-card-title">
      <div class="t-ec-card-title-icon el-icon-s-help"></div>
      <div class="t-ec-card-title-text">{{title}}</div>
    </div>
    <div class="t-ec-card-content">
      <div class="t-ec-card-content-echarts" ref="echarts"></div>
      <div class="t-ec-card-content-empty" v-if="!options.dataSize">
        <t-result type='empty' text="暂无数据" style="padding:0;"></t-result>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts"
export default {
  name: "EcCard",
  props:{
    title:String,
    marginRight:[Number,String],
    width:[Number,String],
    options:{
      type:Object,
      default:()=>({})
    }
  },
  watch:{
    options:{
      handler(val){
        this.change()
      },
      deep:true,
      // immediate:true
    }
  },
  data(){
    return {
      ec:null
    }
  },
  mounted() {
    this.init()
  },
  methods:{
    init(){
      if (!this.$refs.echarts)return;
      this.ec = echarts.init(this.$refs.echarts);
      this.change();
      this.ec.resize();
    },
    change(){
      if (!this.ec)
        this.init();
      else
        this.ec?.setOption(this.options)
    }
  }
}
</script>

<style scoped lang="scss">
.t-ec-card{
  &-title{
    margin-left: 10rem;
    margin-bottom: 24rem;
    display: flex;
    align-items: center;
    &-icon{
      width: 20rem;
      height: 20rem;
      margin-right: 20rem;
      font-size: 20rem;
      color: #1C4EFD;

    }
    &-text{
      font-size: 24rem;
      color: #1E3AA2;
      font-weight: 600;
    }
  }
  &-content{
    background-color: white;
    height: 320rem;
    border-radius: 8rem;
    position:relative;
    &-empty{
      position: absolute;
      left: 0;
      top:0;
      z-index: 10;
      width:100%;
      height:100%;
      background-color: white;
      display:flex;
      justify-content: center;
      align-items: center;
    }
    //overflow: hidden;
    &-echarts{
      height: 100%;
    }
  }

}
</style>

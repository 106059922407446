<template>
<div class="overall-statistics">
  <div class="top">
    <div class="search">
      <el-select size="small" @enter="onSearch" v-model="search.task_config_id" placeholder="筛选任务周期">
        <el-option v-for="item in searchConfig.task" :label="item.task_name" :value="item.task_config_id" :key="'task_name-'+item.task_config_id"></el-option>
      </el-select>
      <el-select size="small" @enter="onSearch" v-model="search.school_id" placeholder="筛选校区">
        <el-option v-for="item in searchConfig.school" :label="item.name" :value="item.id" :key="'school_id'+item.id"></el-option>
      </el-select>
      <el-select size="small" @enter="onSearch" v-model="search.grade_id" placeholder="筛选年级">
        <el-option v-for="item in searchConfig.grade" :label="item.name" :value="item.id" :key="'grade_id'+item.id"></el-option>
      </el-select>
      <div class="buttons">
        <el-button size="small" type="primary" @click="onSearch">搜索</el-button>
        <el-button size="small" @click="onReset" style="margin-left: 20rem">重置</el-button>
      </div>
    </div>
    <div class="card">
      <top-card text="需辅导学生人数" :number="people.need_to_complete"></top-card>
      <top-card text="已开始辅导学生人数" :number="people.in_complete"></top-card>
      <top-card text="已完成学生人数" :number="people.done_complete"></top-card>
      <top-card text="总人数" :number="people.all_complete"></top-card>
    </div>
  </div>
  <div class="echarts">
    <div class="echarts-box">
      <ec-card title="学生分型分布" width="650" margin-right="28" :options="ec1" />
      <ec-card title="学业需求" width="934" margin-right="0" :options="ec2" />
    </div>
    <div class="echarts-box">
      <ec-card title="知识需求" width="650" margin-right="28" :options="ec3" />
      <ec-card title="试卷分析失分类型" width="934" margin-right="0" :options="ec4" />
    </div>
  </div>
  <div class="bottom">
    <bottom-table :data="data" :table-config="tableConfig" title="辅导量排行" subtitle="按辅导完成的人数计算"></bottom-table>
    <bottom-table :data="data2" :table-config="tableConfig2" title="好评榜" subtitle="好评来自学生的真实评价"></bottom-table>
    <bottom-table :data="data3" :table-config="tableConfig3" title="学生表现高频词" subtitle="反应学生日常表现，高频词会定时更新"></bottom-table>
  </div>
</div>
</template>

<script>
import EcCard from "./components/EcCard";
import TopCard from "./components/TopCard";
import BottomTable from "./components/BottomTable";
import {utils} from "@/utils/utils"
import {getSchoolInfo} from "@/api/common";
let ss = utils.ss;
const ec2LineColor = {
  "弱需求":[{
    offset: 0, color: '#F78585' // 0% 处的颜色
  }, {
    offset: 1, color: '#F54C4C' // 100% 处的颜色
  }],
  "中需求":[{
    offset: 0, color: '#FF9A2E' // 0% 处的颜色
  }, {
    offset: 1, color: '#FFBA52' // 100% 处的颜色
  }],
  "强需求":[{
    offset: 0, color: '#49CD4B' // 0% 处的颜色
  }, {
    offset: 1, color: '#7BE47C' // 100% 处的颜色
  }],
};
const ec2Data = {
      value:0,
      height:ss(42),
      label:{
        show:true,
        formatter:"{c}%",
        position:"right",
        color:"#1C4EFD"
      },
      itemStyle:{
        borderRadius: [0,ss(8),ss(8),0],
        color:{
          type: 'linear',
          x: 1,
          y: 1,
          x2: 0,
          y2: 0,
          colorStops: [{
            offset: 0, color: '#F78585' // 0% 处的颜色
          }, {
            offset: 1, color: '#F54C4C' // 100% 处的颜色
          }],
        }
      }
    };
const ec4LineColor = {
  "习惯型":[
      {
        offset: 0, color: '#7BE47C' // 0% 处的颜色
      }, {
        offset: 1, color: '#49CD4B' // 100% 处的颜色
      }
  ],
  "技巧与基本功型":[
    {
      offset: 0, color: '#FFBA52' // 0% 处的颜色
    }, {
      offset: 1, color: '#FF9A2E' // 100% 处的颜色
    }
  ],
  "心态型":[
    {
      offset: 0, color: '#6586F5' // 0% 处的颜色
    }, {
    offset: 1, color: '#7595FF' // 100% 处的颜色
  }
  ],
  "能力型":[
    {
      offset: 0, color: '#935DFE' // 0% 处的颜色
    }, {
    offset: 1, color: '#9472F7' // 100% 处的颜色
  }
  ],
  "失误型":[
      {
        offset: 0, color: '#F78585' // 0% 处的颜色
      }, {
      offset: 1, color: '#F54C4C' // 100% 处的颜色
    }
    ],

};
const ec4Data = {
          value:0,
          height:ss(42),
          label:{
            show:true,
            formatter:"{c}%",
            position:"right",
            color:"#1C4EFD"
          },
          itemStyle:{
            borderRadius: [0,ss(8),ss(8),0],
            color:{
              type: 'linear',
              x: 1,
              y: 1,
              x2: 0,
              y2: 0,
              colorStops: [],
            }
          }
        }
export default {
  methods:{
    init(){
      // 获取校区和年级
      getSchoolInfo(2).then(res=>{
        let {school,action_grade} = res.data;
        this.searchConfig.school = school;
        this.searchConfig.grade = action_grade;
      });
      this.$_axios2.get("/api/coach-statistics/coach-conf",{logic:1}).then(res=>{
        this.searchConfig.task = res.data;
      })
    },
    getData(){
      let search = this.search;
      this.$_axios2.get("/api/coach-statistics/coach-profile",{logic:1,params:search}).then(res=>{
        let {student_total,student_type,academic,knowledge,paper_analysis,coach_num_rank,good_num_rank,entry_rank} = res.data;
        this.people = student_total;
        this.ec1.series[0].data = student_type;
        this.ec1.dataSize = student_type.length;
        // 学生分型分布
        this.ec1 = JSON.parse(JSON.stringify(this.ec1));
        // 学业需求
        this.ec2.yAxis.data = [];
        this.ec2.series[0].data = academic.reverse().map((item,index)=>{
          let key = item.name;
          let res = JSON.parse(JSON.stringify(ec2Data));
          res.value = item.value;
          res.itemStyle.color.colorStops = ec2LineColor[key];
          this.ec2.yAxis.data[index] = key;
          return res;
        });
        this.ec2.dataSize = academic.length;
        // 知识需求
        this.ec3.series[0].data = knowledge.map(item=>{
          return {
            name:item.name.title,
            value:item.value
          }
        })
        this.ec3.dataSize = knowledge.length;
        // 试卷分析失分类型
        this.ec4.yAxis.data = [];
        this.ec4.series[0].data = paper_analysis.map((item,index)=>{
          let key = item.name;
          let res = JSON.parse(JSON.stringify(ec4Data));
          res.value = item.value;
          res.itemStyle.color.colorStops = ec4LineColor[key];
          this.ec4.yAxis.data[index] = key;
          return res;
        })
        this.ec4.dataSize = paper_analysis.length;
        // 辅导量排行
        this.data = coach_num_rank;
        // 评价榜
        this.data2 = good_num_rank;
        // 高频词
        this.data3 = entry_rank;
      })
    },
    onSearch(){
      this.getData();
    },
    onReset(){
      this.search = {};
      this.getData();
    }
  },
  data(){
    return {
      // 搜索配置
      searchConfig:{
        school:[],
        grade:[],
        task:[]
      },
      // 人数统计
      people:{
        need_to_complete:0,
        in_complete:0,
        done_complete:0,
        all_complete:0,
      },
      // 排名,姓名,教学学科,辅导人数
      tableConfig:[
        {
          label:"排名",
          width:"75rem"
        },
        {
          label:"姓名",
          prop:"teacher_name"
        },
        {
          label:"教学学科",
          prop:"subject_name"
        },
        {
          label:"辅导人数",
          prop:"done_complete",
          width:"90rem"
        }
      ],
      // 排名,姓名,教学学科,好评率
      tableConfig2:[
        {
          label:"排名",
          width:"75rem"
        },
        {
          label:"姓名",
          prop:"teacher_name"
        },
        {
          label:"教学学科",
          prop:"subject_name"
        },
        {
          label:"好评率",
          prop:"good_num",
          width:"90rem"
        }
      ],
      // 排名,高频词,次数,
      tableConfig3:[
        {
          label:"排名",
          width:"75rem"
        },
        {
          label:"高频词",
          prop:"name"
        },
        {
          label:"次数",
          prop:"number",
          width:"90rem",
        },
      ],
      data:[],
      data2:[],
      data3:[],
      search:{
        task_config_id:null,
        school_id:null,
        grade_id:null,
      },
      ec1:{
        dataSize:0,
        tooltip: {
          trigger: 'item',
          valueFormatter:(val)=>val+"%"
        },
        legend: {
          top: 'middle',
          left: ss(74),
          orient:"vertical",
          icon:"rect",
          itemWidth:ss(18),
          itemHeight:ss(18),
          itemGap:ss(26),
        },
        color:["#2FD932","#BBCBFF","#AB87FF","#FF6060","#FE9454"],
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '90%'],
            avoidLabelOverlap: false,
            left:this.$utils.ss(320),
            itemStyle: {
              borderRadius: ss(10),
              borderColor: '#fff',
              borderWidth: this.$utils.ss(8)
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                formatter:"{d}%",
                show: false,
                fontSize: this.$utils.ss(40),
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: []
          }
        ]
      },
      ec2:{
        dataSize:0,
        grid:{
          left:ss(177),
          top:ss(24),
          bottom:ss(37),
          right:ss(167)
        },
        xAxis: {
          type: 'value',
          max:100,
          min:0,
          axisLabel:{
            formatter:"{value}%",
            color:"#1E3AA2",
            fontSize:ss(10)
          },
          splitLine:{
            lineStyle:{
              color:"rgba(170, 196, 255, 0.30)"
            }
          },
          axisLine:{
            show:true,
            lineStyle:{
              color:"#dbdde4"
            }
          }
        },
        yAxis: {
          type: 'category',
          axisLabel:{
            color:"#1C4EFD",
            fontSize:ss(16)
          },
          axisLine:{
            show:true,
            lineStyle:{
              color:"#dbdde4"
            }
          },
          data:["弱需求","中需求","强需求"],
        },
        series: [
          {
            barWidth:ss(42),
            data: [
            ],
            type: 'bar'
          }
        ]
      },
      ec3:{
        dataSize:0,
        tooltip: {
          trigger: 'item',
          valueFormatter:(val)=>val+"%"
        },
        legend: {
          top: 'middle',
          left: ss(74),
          orient:"vertical",
          icon:"rect",
          itemWidth:ss(18),
          itemHeight:ss(18),
          itemGap:ss(26),
        },
        color:["#2FD932","#BBCBFF","#FF6060","#FE9454"],
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '90%'],
            avoidLabelOverlap: false,
            left:ss(320),
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: ss(8)
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                formatter:"{d}%",
                show: false,
                fontSize: ss(40),
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: []
          }
        ]
      },
      ec4:{
        dataSize:0,
        grid:{
          left:ss(177),
          top:ss(24),
          bottom:ss(37),
          right:ss(167)
        },
        xAxis: {
          type: 'value',
          max:100,
          min:0,
          axisLabel:{
            formatter:"{value}%",
            color:"#1E3AA2",
            fontSize:ss(10)
          },
          splitLine:{
            lineStyle:{
              color:"rgba(170, 196, 255, 0.30)"
            }
          },
          axisLine:{
            show:true,
            lineStyle:{
              color:"#dbdde4"
            }
          }
        },
        yAxis: {
          type: 'category',
          axisLabel:{
            color:"#1C4EFD",
            fontSize:ss(16)
          },
          axisLine:{
            show:true,
            lineStyle:{
              color:"#dbdde4"
            }
          },
          data:["习惯型","技巧与基本功型","心态型","能力型","失误型"],
        },
        series: [
          {
            barWidth:ss(30),
            data: [],
            type: 'bar'
          }
        ]
      },
    }
  },
  mounted() {
    this.init();
    this.getData();
  },
  components:{
    EcCard,
    TopCard,
    BottomTable
  }
}
</script>

<style scoped lang="scss">
.overall-statistics{
  background-color: #F9FAFD !important;
  padding: 18rem 24rem;
  margin-left: 0 !important;
  width:100% !important;
  .top{
    background-color: white;
    padding: 24rem;
    box-sizing: border-box;
    border-radius: 8rem;
    margin-bottom: 24rem;
    .search{
      margin-bottom: 24rem;
      display: flex;
      align-items: center;
      ::v-deep .el-select,.el-date-editor{
        margin-right: 20rem;
      }
      .buttons{
        margin-left: 20rem;
      }
    }
    .card{
      display: flex;
      justify-content: space-between;
    }
  }
  .echarts{
    &-box{
      display: flex;
      justify-content: space-between;
      .t-ec-card{
        margin-bottom: 24rem;
      }
    }

  }
  .bottom{
    display: flex;
    justify-content: space-between;
  }
}
</style>
